import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ConsistentColours, DollarNumberFormatter, ensureNumber, intersection, Nullable, PercentageNumberFormatter } from '../../../util/Util';
import { Bar, BarChart, CartesianGrid, Legend, PolarGrid, RadarChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, Radar, PolarAngleAxis, PolarRadiusAxis } from 'recharts';
import Grid2 from '@mui/material/Unstable_Grid2';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, Typography, Tooltip as MuiTooltip } from '@mui/material';
import { BestPractice } from './BestPractices';
import logo from '../../../assets/mcmaccx_logo.png'
import styles from './BestPractices.module.scss'
import { Config } from '../../../util/Config';
import { StyledToggleButtonGroup } from '../../../components/StyledComponents';
import { UserModel } from '../../../models/UserModel';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface Props {
    bestPractices: BestPractice[],
    dollarNumberFormatter: (v: number) => string;
    activeProject: number,
    activeCategory: number,
    user: UserModel
}

interface TableCategory {
    [key: string]: number | string;
}

interface ChartDisplayData {
    [key: string]: TableCategory
}

export interface TableData {
    dataKeys: string[]
    data: {
        [key: string]: TableCategory
    },
    totals: {
        [key: string]: TableCategory
    }
    radarData: {
        [key: string]: TableCategory
    },
    radarMin: number // Either 0 or -1 depending on radar values. Would be -1 if a radar entry was 0,0,100 to make it visible
    totalPPPI: number,
    optimalPPPI: number,
    totalCapex: number
}

enum ChartType {
    Bar,
    Radar
}

enum DisplayType {
    BestPractices,
    Total,
    Advanced
}


export const CONSTANTS = {
    TOTAL_PPPI_NAME_CONST: 'Total PPPI',
    CAPEX: 'capex',
}

export const BP_DISPLAY_COLUMNS: {
    key: string,
    name: string,
    hidden?: true
}[] = [{
    key: 'natural',
    name: 'Natural'
}, {
    key: 'human',
    name: 'Human'
}, {
    key: 'financial',
    name: 'Financial'
}, {
    key: CONSTANTS.CAPEX,
    name: 'ERROR',
    hidden: true
}];

// This is a 1-1 mapping with the key names from googleSheetLogic.ts
// They are remapped here so we can use typescript to ensure type safety
// and make future changes if needed. There is some duplication with the
// list above as well but its easier to track like this.
const ADVANCED_TABLE_COLUMNS = {
    planet: {
        mtco2e_avoided: 'mtco2e_avoided',
        cost_per_mtco2e_avoided: 'cost_per_mtco2e_avoided',
        dollar_scc_air_pollution_avoided: 'dollar_scc_air_pollution_avoided',
        dollar_scc_co2e_avoided: 'dollar_scc_co2e_avoided'
    },
    people: {
        health_impact: 'health_impact',
        health_impact_per_occupant: 'health_impact_per_occupant',
        employee_productivity: 'employee_productivity',
        employee_productivity_per_occupant: 'employee_productivity_per_occupant',
    },
    profit: {
        npv_per_sq_ft: 'npv_per_sq_ft',
        npv_per_occupant: 'npv_per_occupant',
        s_roi: 's_roi',
    },
    p3iPerspective: {
        pppi_bcr: 'pppi_bcr',
    },
    capex: 'capex',
    human: 'human',
    financial: 'financial',
    natural: 'natural',
    total_table_npv: 'total_table_npv',
}

enum SectionKey {
    Community,
    Occupant,
    Owner,
    P3IPerspective
}

const ADVANCED_TABLE_SECTIONS = [{
    name: 'Community (Planet)',
    key: SectionKey.Community,
    colSpan: 4
}, {
    name: 'Occupant (People)',
    key: SectionKey.Occupant,
    colSpan: 4
}, {
    name: 'Owner (Profit)',
    key: SectionKey.Owner,
    colSpan: 3
}, {
    name: 'P3I Perspective',
    key: SectionKey.P3IPerspective,
    colSpan: 4
}]

// eslint-disable-next-line
const zeroOnNull = (v: number | null | undefined): number => (v == null) ? 0 : v;

const containerSx = {
    width: '100%',
    maxHeight: '700px',
    // maxWidth: '1300px',
    position: 'relative'
}

const columnGroupSx = {
    color: 'white',
    textAlign: 'center'
}

export default function BestPracticeChart({ bestPractices, dollarNumberFormatter, activeProject, activeCategory, user }: Props) {

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const colors = useMemo(() => new ConsistentColours(), [activeProject, activeCategory]);
    const [chartType, setChartType] = useState<ChartType>(ChartType.Bar)
    const [displayType, setDisplayType] = useState<DisplayType>(DisplayType.BestPractices)
    const [radarTooltip, setRadarTooltip] = useState<Nullable<{ x: string, y: string, key: string }>>(null);
    const [radarChartHoverInfoKey, setRadarChartHoverInfoKey] = React.useState<Nullable<string>>(null);
    const [hiddenSections, setHiddenSections] = useState<{ [key: number]: boolean }>({});

    /**
     * A bunch of computed data needed for the chart/table displays
     */
    const computedData: TableData = useMemo<TableData>(() => {
        const graphData: TableData = { dataKeys: [], data: {}, totals: {}, radarData: {}, totalPPPI: 0, optimalPPPI: 0, radarMin: 0, totalCapex: 0 };

        const mainColumns = BP_DISPLAY_COLUMNS.reduce((prev, v) => {
            prev[v.key] = !v.hidden
            return prev;
        }, {} as { [key: string]: boolean })

        for (const bp of bestPractices) {

            if (!bp.enabled) continue
            console.log(mainColumns, bp.sliderWeight / 100)

            // Use a CONST if we are totalling, otherwise use the best practice name
            const name = bp.name;

            // Iterate through the remarks and add each one
            intersection(Object.keys(bp.table_data?.better), Object.keys(bp.table_data?.best)).forEach(columnKey => {
                // Force this to be uppercase for object referencing later...
                // const columnKey = displayColumn.key;
                const weight = bp.sliderWeight / 100
                const val = (1 - weight) * zeroOnNull(bp.table_data.better?.[columnKey]) + weight * zeroOnNull(bp.table_data.best?.[columnKey]);
                const optimalVal = zeroOnNull(bp.table_data.best?.[columnKey])// || bp.table_data.better?.[columnKey]);
                if (columnKey === CONSTANTS.CAPEX) {
                    graphData.totalCapex += val;
                    return;
                }
                if (!graphData.data[columnKey]) {
                    graphData.data[columnKey] = {};
                    graphData.totals[columnKey] = {};
                }

                // This performs summing if the key is the same, a value-set otherwise
                graphData.data[columnKey][name] = (graphData.data[columnKey][name] as number || 0) + val;
                graphData.totals[columnKey][CONSTANTS.TOTAL_PPPI_NAME_CONST] = (graphData.totals[columnKey][CONSTANTS.TOTAL_PPPI_NAME_CONST] as number || 0) + val;
                if (mainColumns[columnKey]) graphData.totalPPPI += val;
                if (mainColumns[columnKey]) graphData.optimalPPPI += optimalVal;
            });

            // Create the radar graph data on a per-best-practice basis
            /*if (displayType === DisplayType.BestPractices) {
                BP_DISPLAY_COLUMNS.forEach(displayColumn => {
                    // Force this to be uppercase for object referencing later...
                    const columnKey = displayColumn.key;

                    if (displayColumn.hidden) return;

                    if (!graphData.radarData[columnKey]) graphData.radarData[columnKey] = {
                        name: titleCase(displayColumn.name),
                    };
                    const weight = bp.sliderWeight / 100
                    const val = (1 - weight) * zeroOnNull(bp.table_data.better?.[columnKey]) + weight * zeroOnNull(bp.table_data.best?.[columnKey]);
                    graphData.radarData[columnKey][bp.name] = Math.min(100, Math.max((val / bpPPI) * 100, 0));
                    // If one of the graph values is 100 then the other two are 0 and we have an annoying straight line, so we have to
                    // decrease the radar graph axis to -1 so the line is visible...
                    if (graphData.radarData[columnKey][bp.name] === 100) graphData.radarMin = -1;
                })
            }*/

            if (!graphData.dataKeys.includes(name)) graphData.dataKeys.push(name);
        }

        console.log(graphData)

        // Create the radar graph based on the total sum. Has to be outside the loop so the total pppi is available.
        /*if (displayType === DisplayType.Total) {
            for (const key of Object.keys(graphData.data)) {
                graphData.radarData[key] = {
                    name: titleCase(key),
                    [CONSTANTS.TOTAL_PPPI_NAME_CONST]: Math.min(100, Math.max(((graphData.data[key][CONSTANTS.TOTAL_PPPI_NAME_CONST] as number) / graphData.totalPPPI) * 100, 0))
                }
                // If one of the graph values is 100 then the other two are 0 and we have an annoying straight line, so we have to
                // decrease the radar graph axis to -1 so the line is visible...
                if (graphData.radarData[key][CONSTANTS.TOTAL_PPPI_NAME_CONST] === 100) graphData.radarMin = -1;
            }
        }*/

        return graphData;
    }, [bestPractices])

    /**
     * Filter from the overall dataset into the chart data to use.
     */
    const chartData: ChartDisplayData = useMemo<ChartDisplayData>(() => {
        const res: ChartDisplayData = {};
        if (displayType === DisplayType.Advanced) return res;
        BP_DISPLAY_COLUMNS.forEach(displayCol => {
            if (displayType === DisplayType.BestPractices && computedData.data[displayCol.key]) res[displayCol.key] = {
                ...computedData.data[displayCol.key],
                name: displayCol.name
            };
            if (displayType === DisplayType.Total && computedData.totals[displayCol.key]) res[displayCol.key] = {
                ...computedData.totals[displayCol.key],
                name: displayCol.name
            };
        })
        return res;
    }, [computedData, displayType])

    /**
     * PPPI stat posting
     */
    useEffect(() => {
        let newRender = false;
        for (const b of bestPractices) {
            if (b.enabled) {
                newRender = true;
                break;
            }
        }

        if (newRender) {
            user.postPPPIStat({
                value: computedData.totalPPPI,
                bpSelected: computedData.dataKeys
            }).then().catch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bestPractices])

    /**
     * Cached callbacks for performance since we use them alot
     */
    const getValueOrNA = useCallback((key: string, name: string, formatter?: (a: string | number) => string) => {
        const data = computedData.data[key]?.[name];
        if (data == null) return 'N/A';
        return formatter ? formatter(data) : data;
    }, [computedData])

    const getRatio = useCallback((numerator: string, denominator: string, name: string, formatter?: (a: string | number) => string) => {
        const num = computedData.data[numerator]?.[name];
        const denom = computedData.data[denominator]?.[name];
        if (num == null || denom == null) return 'N/A';
        return formatter ? formatter(ensureNumber(num) / ensureNumber(denom)) : ensureNumber(num) / ensureNumber(denom);
    }, [computedData]);

    return <>
        <Grid2 container spacing={ 1 } sx={ { margin: 0, padding: 0, height: '100%' } } direction={ 'column' } alignItems={ 'center' } justifyContent={ 'center' }>
            <Grid2 container justifyContent={ 'center' } alignItems={ 'center' } paddingTop={ 2 }>
                <Grid2>
                    <a href={ Config.links.mcmaccxWebsite } target="_blank" rel="noopener noreferrer">
                        <div className={ styles.logo }>
                            <img src={ logo } alt=""/>
                        </div>
                    </a>
                </Grid2>
                <Grid2>
                    <Typography component={ 'div' } padding={ 0 } variant={ 'h6' } textAlign={ 'center' }>Advanced Analytics</Typography>
                </Grid2>
            </Grid2>
            <Grid2 container justifyContent={ 'center' }>
                <Grid2>
                    <Typography component={ 'div' } padding={ 0 } variant={ 'body1' } textAlign={ 'center' } paddingBottom={ 1 }>Total PPPI: { DollarNumberFormatter(computedData.totalPPPI) }</Typography>
                </Grid2>
                <Grid2>|</Grid2>
                <Grid2>
                    <Typography component={ 'div' } padding={ 0 } variant={ 'body1' } textAlign={ 'center' } paddingBottom={ 1 }>Total Burden: { DollarNumberFormatter(computedData.optimalPPPI - computedData.totalPPPI) }</Typography>
                </Grid2>
                { computedData.totalCapex > 0 &&
                  <>
                    <Grid2>|</Grid2>
                    <Grid2>
                      <Typography component={ 'div' } padding={ 0 } variant={ 'body1' } textAlign={ 'center' } paddingBottom={ 1 }>Total CAPEX: { DollarNumberFormatter(computedData.totalCapex) }</Typography>
                    </Grid2>
                  </> }
            </Grid2>
            { computedData.dataKeys.length > 0 &&
              <>
                <Grid2 container spacing={ 2 } justifyContent={ 'center' } alignItems={ 'center' }>
                  <Grid2 xs={ 12 } lg={ 'auto' }>
                    <StyledToggleButtonGroup
                      value={ chartType }
                      exclusive
                      onChange={ (e, nv) => nv !== null ? setChartType(nv) : null }
                      aria-label="Platform"
                    >
                        {/*<ToggleButton value={ ChartType.Bar }>Bar Chart</ToggleButton>*/ }
                        {/*<ToggleButton value={ ChartType.Radar }>Radar Chart</ToggleButton>*/ }
                    </StyledToggleButtonGroup>
                  </Grid2>
                  <Grid2>
                    <StyledToggleButtonGroup
                      value={ displayType }
                      exclusive
                      onChange={ (e, nv) => nv !== null ? setDisplayType(nv) : null }
                      aria-label="Platform"
                    >
                      <ToggleButton value={ DisplayType.BestPractices }>Best Practices</ToggleButton>
                      <ToggleButton value={ DisplayType.Total }>Total PPPI</ToggleButton>
                      <ToggleButton value={ DisplayType.Advanced }>Advanced</ToggleButton>
                    </StyledToggleButtonGroup>
                  </Grid2>
                </Grid2>
                  { displayType !== DisplayType.Advanced &&
                    <Grid2 xs={ true } sx={ containerSx } paddingTop={ 1 }>
                        { chartType === ChartType.Bar &&
                          <ResponsiveContainer width="100%" height="100%" minHeight={ 600 }>
                            <BarChart
                              data={ Object.values(chartData) }
                              margin={ {
                                  top: 5,
                                  right: 30,
                                  left: 20,
                                  bottom: 5,
                              } }
                            >
                              <CartesianGrid strokeDasharray="3 5"/>
                              <XAxis dataKey="name"/>
                              <YAxis tickFormatter={ dollarNumberFormatter }
                                     scale={ 'sqrt' }
                                     domain={ [(min: number) => min < -200000 ? min - 200000 : min < 0 ? min - 50000 : -10000, 'dataMax + 200000'] }/>
                              <ReferenceLine y={ 0 } stroke="red" strokeDasharray="3 3"/>
                              <Tooltip formatter={ value => dollarNumberFormatter(value as number) }/>
                              <Legend/>
                                { (displayType === DisplayType.BestPractices ? computedData.dataKeys : [CONSTANTS.TOTAL_PPPI_NAME_CONST]).map((key, i) => <Bar dataKey={ key } key={ i } fill={ colors.get(key) }/>) }
                            </BarChart>
                          </ResponsiveContainer> }

                        { chartType === ChartType.Radar &&
                          <>
                            <Tooltip active={ !!radarChartHoverInfoKey }
                                     position={ { x: 20, y: 20 } }
                                     label={ <b>{ radarChartHoverInfoKey }</b> }
                                     formatter={ value => dollarNumberFormatter(value as number) }
                                     payload={ Object.values(chartData).map(val => ({ name: val.name, value: val[radarChartHoverInfoKey || ''], color: '#2f2f2f' })) }/>


                            <Tooltip active={ !!radarTooltip } animationDuration={ 0 }
                                     wrapperStyle={ { background: 'white', zIndex: 1, transform: `translate(${ radarTooltip?.x }, ${ radarTooltip?.y })` } }
                                     formatter={ value => PercentageNumberFormatter(value as number) }
                                     label={ <b>{ radarTooltip?.key }</b> }
                                     payload={
                                         Object.entries(computedData.radarData[radarTooltip?.key.toUpperCase() || ''] || [])
                                             .filter(([k]) => k !== 'name')
                                             .map(([key, val]) => ({ name: key, value: val, color: colors.get(key) }))
                                     }/>


                            <ResponsiveContainer width="100%" height="100%" minHeight={ 600 }>

                              <RadarChart data={ Object.values(computedData.radarData) } onMouseLeave={ () => setRadarChartHoverInfoKey(null) }>
                                <PolarGrid radialLines={ true }/>
                                <PolarAngleAxis
                                  tick={ (props: any) => {
                                      const { x, y, stroke, textAnchor, payload } = props;
                                      const xOffset = (() => {
                                          switch (textAnchor) {
                                              case 'start':
                                                  return 75
                                              default:
                                              case 'middle':
                                                  return 50
                                              case 'end':
                                                  return 25
                                          }
                                      })()
                                      return <text x={ x } y={ y } stroke={ stroke } offset={ payload.offset } textAnchor={ textAnchor }
                                                   onMouseEnter={ () => setRadarTooltip({ x: `calc(${ x }px - ${ xOffset }%)`, y: `calc(${ y }px - 10%)`, key: payload.value }) }
                                                   onMouseLeave={ () => setRadarTooltip(null) }
                                      >
                                          { payload.value }
                                      </text>
                                  } } fill={ 'black' } dataKey={ "name" }/>

                                  {/*// @ts-ignore this is required cause the ticks thing is stupid...*/ }
                                <PolarRadiusAxis ticks={ [0, 25, 50, 75, 100] } tick={ { fill: '#2f2f2f' } } tickFormatter={ PercentageNumberFormatter } scale={ 'linear' } angle={ 30 } domain={ [computedData.radarMin, 100] }/>
                                <Legend/>
                                  { computedData.dataKeys.map((key, i) => <Radar
                                      onMouseOver={ () => setRadarChartHoverInfoKey(key) }
                                      onMouseDown={ () => setRadarChartHoverInfoKey(key) }
                                      dataKey={ key } key={ i } fillOpacity={ 0.6 } fill={ colors.get(key) }/>) }
                              </RadarChart>

                            </ResponsiveContainer>
                          </>
                        }

                    </Grid2> }
                  { displayType === DisplayType.Advanced &&
                    <Grid2 xs={ true } sx={ containerSx } padding={ 2 }>
                      <TableContainer component={ Paper }>
                        <Table className={ styles.advancedTable }>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={ columnGroupSx } className={ styles.columnGroup }></TableCell>
                                { ADVANCED_TABLE_SECTIONS.map(section => hiddenSections[section.key] ?
                                    <TableCell sx={ columnGroupSx } className={ styles.columnGroup } key={ section.name }>
                                        <MuiTooltip title={ 'Show ' + section.name }>
                                            <IconButton size={ 'small' } onClick={ () => setHiddenSections({ ...hiddenSections, [section.key]: false }) }>
                                                <AddIcon sx={ { color: 'white' } }/>
                                            </IconButton>
                                        </MuiTooltip>
                                    </TableCell> :
                                    <TableCell sx={ columnGroupSx } className={ styles.columnGroup } colSpan={ section.colSpan } key={ section.name }>
                                        { section.name }
                                        <div className={ styles.hideColumnsButton }>
                                            <MuiTooltip title={ 'Hide ' + section.name }>
                                                <IconButton size={ 'small' } onClick={ () => setHiddenSections({ ...hiddenSections, [section.key]: true }) }>
                                                    <RemoveIcon sx={ { color: 'white' } }/>
                                                </IconButton>
                                            </MuiTooltip>
                                        </div>
                                    </TableCell>) }
                            </TableRow>
                            <TableRow>
                              <TableCell className={ styles.columnGroupEnd }>Name</TableCell>
                                {/* Section 1 */ }
                                { hiddenSections[SectionKey.Community] ? <TableCell className={ styles.collapsedCell }></TableCell> : <>
                                    <TableCell>MTOCO2e Avoided</TableCell>
                                    <TableCell>$&nbsp;C&nbsp;/&nbsp;MTCO2e Avoided</TableCell>
                                    <TableCell>$SCC&nbsp;Air Pollution&nbsp;Avoided</TableCell>
                                    <TableCell className={ styles.columnGroupEnd }>$SCC&nbsp;CO2e Avoided</TableCell>
                                </> }
                                {/* Section 2 */ }
                                { hiddenSections[SectionKey.Occupant] ? <TableCell className={ styles.collapsedCell }></TableCell> : <>
                                    <TableCell>Health Impact</TableCell>
                                    <TableCell>Health&nbsp;Impact&nbsp;/ Occupant</TableCell>
                                    <TableCell>Employee Productivity</TableCell>
                                    <TableCell className={ styles.columnGroupEnd }>Employee&nbsp;Productivity&nbsp;/ Occupant</TableCell>
                                </> }
                                {/* Section 3 */ }
                                { hiddenSections[SectionKey.Owner] ? <TableCell className={ styles.collapsedCell }></TableCell> : <>
                                    <TableCell>NPV&nbsp;/&nbsp;ft<sup>2</sup></TableCell>
                                    <TableCell>NPV&nbsp;/ Occupant</TableCell>
                                    <TableCell className={ styles.columnGroupEnd } style={ { whiteSpace: 'nowrap' } }>S-ROI</TableCell>
                                </> }
                                {/* Section 4 */ }
                                { hiddenSections[SectionKey.P3IPerspective] ? <TableCell className={ styles.collapsedCell }></TableCell> : <>
                                    <TableCell>McMacCX PPPI&nbsp;BCR</TableCell>
                                    <TableCell>Planet Ratio</TableCell>
                                    <TableCell>People Ratio</TableCell>
                                    <TableCell>Profit Ratio</TableCell>
                                    {/*<TableCell>PPPI Ratio</TableCell>*/ }
                                </> }
                            </TableRow>
                          </TableHead>
                          <TableBody>
                              { computedData.dataKeys.map(bpName => <TableRow key={ bpName }>
                                  <TableCell className={ styles.columnGroupEnd }>
                                      <div className={ styles.nameCol }>{ bpName }</div>
                                  </TableCell>
                                  {/* Section 1 */ }
                                  { hiddenSections[SectionKey.Community] ? <TableCell className={ styles.collapsedCell }></TableCell> : <>
                                      <TableCell>{ getValueOrNA(ADVANCED_TABLE_COLUMNS.planet.mtco2e_avoided, bpName, v => ensureNumber(v).toFixed(2)) }</TableCell>
                                      <TableCell>{ getValueOrNA(ADVANCED_TABLE_COLUMNS.planet.cost_per_mtco2e_avoided, bpName, v => DollarNumberFormatter(ensureNumber(v), 2)) }</TableCell>
                                      <TableCell>{ getValueOrNA(ADVANCED_TABLE_COLUMNS.planet.dollar_scc_air_pollution_avoided, bpName, v => DollarNumberFormatter(ensureNumber(v), 2)) }</TableCell>
                                      <TableCell className={ styles.columnGroupEnd }>{ getValueOrNA(ADVANCED_TABLE_COLUMNS.planet.dollar_scc_co2e_avoided, bpName, v => DollarNumberFormatter(ensureNumber(v), 2)) }</TableCell>
                                  </> }
                                  {/* Section 2 */ }
                                  { hiddenSections[SectionKey.Occupant] ? <TableCell className={ styles.collapsedCell }></TableCell> : <>
                                      <TableCell>{ getValueOrNA(ADVANCED_TABLE_COLUMNS.people.health_impact, bpName, v => DollarNumberFormatter(ensureNumber(v))) }</TableCell>
                                      <TableCell>{ getValueOrNA(ADVANCED_TABLE_COLUMNS.people.health_impact_per_occupant, bpName, v => DollarNumberFormatter(ensureNumber(v), 2)) }</TableCell>
                                      <TableCell>{ getValueOrNA(ADVANCED_TABLE_COLUMNS.people.employee_productivity, bpName, v => DollarNumberFormatter(ensureNumber(v), 2)) }</TableCell>
                                      <TableCell className={ styles.columnGroupEnd }>{ getValueOrNA(ADVANCED_TABLE_COLUMNS.people.employee_productivity_per_occupant, bpName, v => DollarNumberFormatter(ensureNumber(v), 2)) }</TableCell>
                                  </> }
                                  {/* Section 3 */ }
                                  { hiddenSections[SectionKey.Owner] ? <TableCell className={ styles.collapsedCell }></TableCell> : <>
                                      <TableCell>{ getValueOrNA(ADVANCED_TABLE_COLUMNS.profit.npv_per_sq_ft, bpName, v => DollarNumberFormatter(ensureNumber(v), 2)) }</TableCell>
                                      <TableCell>{ getValueOrNA(ADVANCED_TABLE_COLUMNS.profit.npv_per_occupant, bpName, v => DollarNumberFormatter(ensureNumber(v), 2)) }</TableCell>
                                      <TableCell className={ styles.columnGroupEnd }>{ getValueOrNA(ADVANCED_TABLE_COLUMNS.profit.s_roi, bpName, v => PercentageNumberFormatter(ensureNumber(v) * 100, 2)) }</TableCell>
                                  </> }
                                  {/* Section 4 */ }
                                  { hiddenSections[SectionKey.P3IPerspective] ? <TableCell className={ styles.collapsedCell }></TableCell> : <>
                                      <TableCell>{ getValueOrNA(ADVANCED_TABLE_COLUMNS.p3iPerspective.pppi_bcr, bpName, v => ensureNumber(v).toFixed(2)) }</TableCell>
                                      <TableCell>{ getRatio(ADVANCED_TABLE_COLUMNS.natural, ADVANCED_TABLE_COLUMNS.total_table_npv, bpName, v => PercentageNumberFormatter(ensureNumber(v) * 100, 2)) }</TableCell>
                                      <TableCell>{ getRatio(ADVANCED_TABLE_COLUMNS.human, ADVANCED_TABLE_COLUMNS.total_table_npv, bpName, v => PercentageNumberFormatter(ensureNumber(v) * 100, 2)) }</TableCell>
                                      <TableCell>{ getRatio(ADVANCED_TABLE_COLUMNS.financial, ADVANCED_TABLE_COLUMNS.total_table_npv, bpName, v => PercentageNumberFormatter(ensureNumber(v) * 100, 2)) }</TableCell>
                                  </> }
                              </TableRow>) }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid2> }
              </>
            }
        </Grid2>
    </>
}
